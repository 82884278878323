<template>
  <div class="row" style="padding: 1px; border-top: 3px solid #343030">
    <div
      class="col-xxl-4 col-sm-4 col-md-4 col-4"
      v-for="tag in config.tags"
      :key="tag.key"
      style="border-bottom: 3px solid #343030"
    >
      <div class="detail-table-name" v-if="tag.desc">
        <VMenu :triggers="['click']" :hideTriggers="['click']">
          <template #popper>
            <div
              class="card"
              style="
                min-height: 160px;
                max-width: 180px;
                background-color: black;
                color: #efefef;
                padding: 5px;
              "
            >
              <h5
                class="tulum-party-subtitle"
                style="background-color: #61eac8; color: black !important"
              >
                {{ config.name }}
              </h5>
              <p>
                <strong>{{ tag.label }}:</strong> {{ tag.desc }}
              </p>
            </div>
          </template>
          <p
            style="
              color: black !important;
              font-size: 0.7rem;
              font-weight: bold;
              cursor: pointer;
            "
            :style="{ backgroundColor: color }"
          >
            {{ tag.label }}
          </p>
          <div
            class="detail-table-value"
            style="background-color: #2b2a2a; cursor: pointer"
          >
            <p style="font-size: 0.7rem">{{ tag.value }}</p>
          </div>
        </VMenu>
      </div>

      <div v-if="!tag.desc">
        <div class="detail-table-name">
          <p
            class="label label-primary"
            style="font-size: 0.7rem; font-weight: bold"
          >
            {{ tag.label }}
          </p>
        </div>
        <div class="detail-table-value">
          <p style="font-size: 0.7rem">{{ tag.value }}</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ClubDetails',
  props: {
    config: {
      type: Object, // striped | hover
      default: () => {
        return { tags: [] };
      },
    },
    color: {
      type: String,
      default: '#30abc6',
    },
  },
};
</script>
