// Address can live on the cosmic side.
export default Object.freeze({
  'bagatelle-los-cabos': {
    name: 'Bagatelle',
    directions: {
      address: 'El Medano Ejidal, 23479 Cabo San Lucas, Baja California Sur',
      googleMap: 'pb=!1m14!1m8!1m3!1d14703.407598565955!2d-109.9063044!3d22.8819287!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b6365f42a5f%3A0xb24d6234fe6779ba!2sBagatelle%20Los%20Cabos!5e0!3m2!1sen!2smx!4v1712865524762!5m2!1sen!2smx'
    },
    tags:  [
      { label: 'DAY', value: '11AM-6PM', desc: 'Day Club & Restaurant.' },
      { label: 'NIGHT', value: '7PM-2AM', desc: 'Have Special Dinners with shows & Restaurant Parties.' },
      { label: 'SHOW', value: 'DINNER+PARTY', desc: 'Live Shows, Dinner & DJ Sets.' },
      { label: 'MUSIC', value: 'DISCO/HOUSE', desc: 'Live music & DJ Residents.' },
      { label: 'DRESS', value: 'CHIC', desc: 'Smart Casual (Athletic wear, caps, swimwear, and flip-flops are strictly prohibited).' },
      { label: 'GOOD FOR', value: 'DINE & CELEBRATE', desc: 'The perfect spot for an enjoyable dinner with spectacular themed shows.' },
    ],
  },
  'crania-los-cabos': {
    name: 'Crania',
    directions: {
      address: 'Marina Puerto, Poblado La Playa, 23403 San José del Cabo, B.C.S.',
      googleMap: 'pb=!1m18!1m12!1m3!1d3671.0399905060926!2d-109.67655452534089!3d23.05899541496909!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af514104fe7f23%3A0xaaaaa2ccc9c0afbd!2sCRANIA!5e0!3m2!1sen!2smx!4v1712866128322!5m2!1sen!2smx'
    },
    tags:  [
      { label: 'DAY', value: '-', desc: 'Closed during the day.' },
      { label: 'NIGHT', value: '7PM-2AM', desc: 'Thu-Sat opens at 7pm & Sundays at 5pm. Special DJ sets & dinners.' },
      { label: 'SHOW', value: 'DJ SET', desc: 'Themed events, live sets, and international acts.' },
      { label: 'MUSIC', value: 'TECH/HOUSE', desc: 'International and Local DJ Sets.' },
      { label: 'DRESS', value: 'RELAXED', desc: 'Boho Casual (No sportswear/beachwear, and no flipflops).' },
      { label: 'GOOD FOR', value: 'PARTY', desc: 'Unique scenery for parties & dinners.' },
    ],
  },
  "rosa-negra-los-cabos": {
    name: 'Rosa Negra Los Cabos',
    directions: {
      address: 'Carretera Transpeninsular Km 7.3 Cabo del Sol Fracc A-3, 23455 Cabo San Lucas, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14700.387891819422!2d-109.8535079!3d22.9097938!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4d6560025859%3A0x41198e32fb96ebeb!2sRosaNegra%20Latin%20American%20Cuisine!5e0!3m2!1sen!2smx!4v1713981338204!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '5PM-1AM', desc: 'Great restaurant for dinners & special shows.'},
      {label: 'SHOW', value: 'DINNER & SHOW', desc: 'Festive dinner sessions with live music & performances.'},
      {label: 'MUSIC', value: 'LATIN/LIVE', desc: 'DJs or residents with Latin-inspired house and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'DINNER & PARTY', desc: ' The perfect spot for a dinner celebration.'},
    ],
  },
  'taboo-los-cabos': {
    name: 'Taboo',
    directions: {
      address: 'Acuario, Playa El Medano, Zona Hotelera, 23410 Cabo San Lucas, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14702.675594299862!2d-109.9039543!3d22.8886864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4be70f26ab0d%3A0x7f198a78f390eb9c!2sTaboo%20Beach%20Club!5e0!3m2!1sen!2smx!4v1712864624113!5m2!1sen!2smx'
    },
    tags:  [
      { label: 'DAY', value: '11AM-7PM', desc: 'Enjoy the beach club, food, and lively happenings.' },
      { label: 'NIGHT', value: '7PM-1AM', desc: 'Have Special Dinners & Restaurant Parties.' },
      { label: 'SHOW', value: 'DINNER & SHOW', desc: 'Sax & Violin Shows, Live music, and an energetic atmosphere.' },
      { label: 'MUSIC', value: 'LATIN/LIVE', desc: 'DJs or residents with Latin inspired house and live music.' },
      { label: 'DRESS', value: 'RELAXED', desc: 'Beach-themed attire for the day and Smart casual for the dinner time (7:00 pm).' },
      { label: 'GOOD FOR', value: 'PARTY', desc: 'Gourmet dining, lively music, and captivating happenings.' },
    ],
  },
  "chambao-los-cabos": {
    name: 'Chambao Los Cabos',
    price: 3,
    directions: {
      address: 'P.º Malecon San Jose 585, Zona Hotelera, 23405 San José del Cabo, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14685.871284210374!2d-109.7020416!3d23.0433044!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af51028cf7a955%3A0x825c0ada180760d2!2sChambao%20Los%20Cabos%20%7C%20Best%20Steakhouse%20in%20San%20Jose!5e0!3m2!1sen!2smx!4v1715725394782!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '5PM-1AM', desc: 'Great restaurant for dinners & special shows.'},
      {label: 'SHOW', value: 'DINNER & SHOW', desc: 'Festive dinner sessions with live music & performances.'},
      {label: 'MUSIC', value: 'LATIN/LIVE', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {
        label: 'GOOD FOR',
        value: 'DINNER & PARTY',
        desc: 'The perfect spot for a Fashion Grillhouse dinner celebration.'
      },
    ],
  },
  "mamazzita-los-cabos": {
    name: 'Mamazzita',
    price: 4,
    directions: {
      address:
        'Acuario Zona Hotelera Playa El Medano, Lt 1, 23410 Cabo San Lucas, B.C.S., Mexico',
      googleMap:
        'pb=!1m18!1m12!1m3!1d3675.65209476951!2d-109.90677682649758!3d22.8893068212917!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4afce0b72a99%3A0x16040f3e198a1608!2sMamazzita!5e0!3m2!1sen!2ses!4v1728305741465!5m2!1sen!2ses',
    },
    tags: [
      { label: 'DAY', value: '7AM-11AM', desc: 'Open Daily for breakfast.' },
      {
        label: 'NIGHT',
        value: '7PM-11PM',
        desc:
          'Open daily for dinner. ',
      },
      {
        label: 'FOOD',
        value: 'MEXICAN FUSION',
        desc: 'Mexican Soul Cuisine for those who enjoy carefully crafted dishes.',
      },
      {
        label: 'MUSIC',
        value: 'LATIN HOUSE',
        desc:
          'Latin House music for a captivating ambient.',
      },
      {
        label: 'DRESS',
        value: 'SMART-CASUAL',
        desc:
          'Elegant/Smart Casual',
      },
      {
        label: 'GOOD FOR',
        value: 'GROUP PARTY AND DINNER',
        desc:
          'Mamazzita in los Cabos offers an authentic mexican party experience acompannied by the best fusion cuisine.',
      },
    ],
  },
  "funky-geisha-los-cabos": {
    name: 'Funky Geisha Los Cabos',
    price: 5,
    directions: {
      address: 'Acuario, Playa El Medano, Zona Hotelera Lote 2, 23410 Cabo San Lucas, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14702.691454736369!2d-109.9037305!3d22.88854!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b25d3dd583d%3A0x6e9ccdcb0dbba731!2sFunky%20Geisha!5e0!3m2!1sen!2smx!4v1727892346330!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '1PM-7PM', desc: 'Restaurant services.'},
      {label: 'NIGHT', value: '7PM-1AM', desc: 'Asian Cuisine & special shows.'},
      {label: 'SHOW', value: 'DINNER & SHOW', desc: 'Taiko Drum show & dinner sessions with live music.'},
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'DINNER & SHOW', desc: 'Asian inspired dinner with a lively ambiance.'},
    ],
  },
  "craft-los-cabos": {
    name: 'Craft Los Cabos',
    price: 3,
    directions: {
      address: 'Acuario, Playa El Medano, El Medano Ejidal, Zona Hotelera, 23410 Cabo San Lucas, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14702.624058052153!2d-109.9043708!3d22.8891621!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4afce0b72a99%3A0x60447a3646c8be89!2sCraft%20Los%20Cabos%20%7C%20Open%20Fire%20Kitchen!5e0!3m2!1sen!2smx!4v1727894088353!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '-', desc: 'Closed during the day.'},
      {label: 'NIGHT', value: '5PM-1AM', desc: 'Open Fire Kitchen & rooftop views.'},
      {label: 'SHOW', value: 'DINNER & SHOW', desc: 'From Thursday to Sunday enjoy amazing fireworks.'},
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Casual-elegant (No sportswear/beachwear, and no flipflops).'},
      {label: 'GOOD FOR', value: 'DINNER & SHOW', desc: 'Stunning views & Fresh food.'},
    ],
  },
  "tamara-beach-club-los-cabos": {
    name: 'Tamara Los Cabos',
    price: 3,
    directions: {
      address: 'Acuario, Playa El Medano, Zona Hotelera Lote 2, 23410 Cabo San Lucas, 23410 San Lucas, B.C.S.',
      googleMap: 'pb=!1m14!1m8!1m3!1d14702.665952320918!2d-109.9034272!3d22.8887754!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b238cf2cbcf%3A0x57d9b240e8d28d18!2sTamara%20Beach%20Los%20Cabos!5e0!3m2!1sen!2smx!4v1728088820718!5m2!1sen!2smx'
    },
    tags: [
      {label: 'DAY', value: '11AM-7PM', desc: 'A stunning beachfront spot for a relaxed time under the sun.'},
      {label: 'NIGHT', value: '-', desc: 'Closed during the night.'},
      {label: 'SHOW', value: 'DAY CLUB', desc: 'Connect with the sun and the ocean.'},
      {label: 'MUSIC', value: 'LIVE/LATIN', desc: 'Residents DJs and live music.'},
      {label: 'DRESS', value: 'CHIC', desc: 'Beach Attaire.'},
      {label: 'GOOD FOR', value: 'RELAX', desc: 'Stunning day views & Fresh food.'},
    ],
  }





});
